import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeProps as _normalizeProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import { useSlots } from 'vue';
import { VIcon } from '@components/v-icon';
import { useFortuneWheelButton } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FortuneWheelButton',
    props: {
        label: {
            default: ''
        },
        iconName: {},
        isFullWidth: {
            type: Boolean
        },
        isButton: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        "click",
        "keydown",
        "mousedown"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const slots = useSlots();
        const { mainTag, computedTag, computedAttributes, hasDefaultContent, haveIcon, iconProps } = useFortuneWheelButton(props, slots);
        function onClick(event) {
            emit('click', event);
        }
        function onKeyDown(event) {
            emit('keydown', event);
        }
        function onMouseDown(event) {
            emit('mousedown', event);
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            const _directive_data_test = _resolveDirective("data-test");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(computedTag)), {
                ref_key: "mainTag",
                ref: mainTag,
                type: _unref(computedAttributes),
                class: _normalizeClass({
                    [_ctx.$style['fortune-wheel-button']]: true,
                    [_ctx.$style['fortune-wheel-button--icon-only']]: !_ctx.label && !_unref(hasDefaultContent) && !!_ctx.iconName,
                    [_ctx.$style['fortune-wheel-button--full-width']]: _ctx.isFullWidth,
                    [_ctx.$style['fortune-wheel-button--is-label']]: !_ctx.isButton
                }),
                onClick: onClick,
                onKeydown: onKeyDown,
                onMousedown: onMouseDown
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['fortune-wheel-button__inner'])
                        }, [
                            _unref(haveIcon) ? (_openBlock(), _createBlock(_unref(VIcon), _normalizeProps(_mergeProps({
                                key: 0
                            }, _unref(iconProps))), null, 16)) : _createCommentVNode("", true),
                            _renderSlot(_ctx.$slots, "default", {}, ()=>[
                                    _createTextVNode(_toDisplayString(_ctx.label), 1)
                                ])
                        ], 2)
                    ]),
                _: 3
            }, 40, [
                "type",
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'FortuneWheelButton'
                ],
                [
                    _directive_data_test,
                    {
                        el: `fortune-wheel-button--${_ctx.label || _unref(hasDefaultContent) || !_ctx.iconName ? '' : 'icon-only'}`
                    }
                ]
            ]);
        };
    }
});
