import { computed, onUnmounted, ref, toRef, watchEffect } from 'vue';
export default function useFortuneWheel(props, emit) {
    const spinner = ref();
    const ticker = ref();
    const initDegrees = 135;
    // начальный поворот колеса
    const fullCircle = 360;
    const startTurnQuantity = 3;
    // начальное число для диапазона вращений колеса
    const endTurnQuantity = 7;
    // конечное число для диапазона вращений колеса
    const prizeSlice = computed(()=>fullCircle / props.rewards.length);
    const prizeOffset = computed(()=>Math.floor(fullCircle / 2 / props.rewards.length));
    const rotation = ref(initDegrees);
    const isStart = ref(false);
    const isInitTicker = ref(false);
    const isSpinning = ref(false);
    let currentSlice = 0;
    let tickerAnim = 0;
    const dataLoaded = toRef(props, 'isDataLoaded');
    const onWheelStopped = ()=>{
        emit('wheel-stopped');
    };
    const pickRandomFromRange = (min, max)=>{
        const minCeil = Math.ceil(min);
        const maxFloor = Math.floor(max);
        return Math.floor(Math.random() * (maxFloor - minCeil + 1)) + minCeil;
    };
    const spinnerStyle = computed(()=>({
            '--rotate': rotation.value
        }));
    const getSpinnerStyles = ()=>{
        if (spinner.value) return window.getComputedStyle(spinner.value).transform.split('(')[1].split(')')[0].split(',');
        return null;
    };
    const runTickerAnimation = ()=>{
        const values = getSpinnerStyles();
        if (values) {
            const a = Number(values[0]);
            const b = Number(values[1]);
            let rad = Math.atan2(b, a);
            if (rad < 0) rad += 2 * Math.PI;
            const angle = Math.round(fullCircle / 2 / Math.PI * rad);
            const slice = Math.floor(angle / prizeSlice.value);
            if (ticker.value && currentSlice !== slice) {
                ticker.value.style.animation = 'none';
                setTimeout(()=>{
                    if (ticker.value) ticker.value.style.animation = '';
                }, 10);
                currentSlice = slice;
            }
            tickerAnim = requestAnimationFrame(runTickerAnimation);
        }
    };
    function transitionEndHandler() {
        rotation.value %= fullCircle;
        isSpinning.value = false;
        setTimeout(()=>{
            if (ticker.value) isInitTicker.value = false;
        }, 5);
        setTimeout(()=>{
            onWheelStopped();
        }, 1000);
        cancelAnimationFrame(tickerAnim);
    }
    function onClick() {
        return new Promise((resolve)=>{
            isStart.value = false;
            if (spinner.value) spinner.value.style.animation = 'none';
            isInitTicker.value = true;
            isSpinning.value = true;
            if (ticker.value) ticker.value.style.animation = 'none';
            runTickerAnimation();
            const calculateRange = (rangeNumber)=>{
                const start = (rangeNumber - 1) * prizeSlice.value;
                const end = rangeNumber * prizeSlice.value - 1;
                return pickRandomFromRange(start, end);
            };
            const winSectorCoord = Math.floor(initDegrees + fullCircle - calculateRange(props.rewardId));
            rotation.value = Math.floor(fullCircle * pickRandomFromRange(startTurnQuantity, endTurnQuantity) + winSectorCoord);
            if (spinner.value) spinner.value.addEventListener('transitionend', transitionEndHandler);
            resolve();
        });
    }
    watchEffect(()=>{
        if (dataLoaded.value) {
            onClick();
            dataLoaded.value = false;
        }
    });
    onUnmounted(()=>{
        if (spinner.value) spinner.value.removeEventListener('transitionend', transitionEndHandler);
    });
    return {
        spinner,
        ticker,
        spinnerStyle,
        prizeSlice,
        prizeOffset,
        isStart,
        isInitTicker,
        isSpinning,
        onClick,
        onWheelStopped
    };
}
