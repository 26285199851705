import { computed, onMounted, ref, toRef, watch } from 'vue';
import { useRouter } from 'vue-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { str } from 'crc-32';
import RouteName from '@leon-hub/routing-config-names';
import { CasinoRouteName } from '@leon-hub/routing-config-names/src/CasinoRouteName';
import { BonusGameRewardType } from '@leon-hub/api-sdk/src/sdk/sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFortuneWheelStore } from 'web/src/modules/fortune-wheel/store';
import { useFormatMoney } from 'web/src/modules/money/composables';
import LanguageMicroSettings from 'web/src/utils/LanguageMicroSettings';
import { useI18nStore } from 'web/src/modules/i18n/store';
function hash(content) {
    // eslint-disable-next-line no-bitwise
    return (str(content) >>> 0).toString(16);
}
export default function useFortuneWheelPage() {
    const { $translate } = useI18n();
    const i18nStore = useI18nStore();
    const router = useRouter();
    const formatMoney = useFormatMoney();
    const fortuneWheelStore = useFortuneWheelStore();
    const roundKey = toRef(fortuneWheelStore, 'roundKey');
    const roundState = toRef(fortuneWheelStore, 'roundState');
    const roundWinIndex = toRef(fortuneWheelStore, 'roundWinIndex');
    const roundWinDetails = toRef(fortuneWheelStore, 'roundWinDetails');
    const fortuneWheelId = toRef(fortuneWheelStore, 'fortuneWheelId');
    const showSpinButton = toRef(fortuneWheelStore, 'showSpinButton');
    const isAdditionalRoundActive = toRef(fortuneWheelStore, 'isAdditionalRoundActive');
    const isFortuneWheelAvailable = toRef(fortuneWheelStore, 'isFortuneWheelAvailable');
    const { fetchRounds, calculateRound, fetchActiveOrAvailableCampaigns, setShowSpinButton } = useFortuneWheelStore();
    const rewardId = ref(0);
    const isAvailableClick = ref(true);
    const isModalShown = ref(false);
    const isInitialDataLoaded = ref(false);
    const isDataLoaded = ref(false);
    const isButtonForDepositEnabled = ref(false);
    const hasDeposit = ref(false);
    const actualRoundStartDate = ref(0);
    const rewardType = ref();
    const rewardText = ref();
    const showCounter = ref();
    const daysParams = computed(()=>{
        const round = roundState.value?.round;
        return {
            currentDay: String(round?.dayNumber || 1),
            totalDays: String(round?.roundsCount || 1)
        };
    });
    const days = $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_DAYS_SHORT', daysParams);
    const rewards = computed(()=>roundState.value?.round.rewards ?? []);
    const canPurchaseAdditionalRound = computed(()=>roundState.value?.round.canPurchaseAdditionalRound ?? false);
    const rewardDetails = computed(()=>roundWinDetails.value);
    const freespinCount = computed(()=>rewardDetails.value ? rewardDetails.value.freespinCount : null);
    const rewardFreespinText = computed(()=>rewardDetails.value ? {
            text: LanguageMicroSettings.plural([
                $translate('WEB2_FORTUNE_WHEEL_REWARD_TEXT_FREESPIN_ONE').value,
                $translate('WEB2_FORTUNE_WHEEL_REWARD_TEXT_FREESPIN_TWO').value,
                $translate('WEB2_FORTUNE_WHEEL_REWARD_TEXT_FREESPIN_MANY').value
            ], freespinCount.value || 0, i18nStore.locale || 'ru_RU')
        } : null);
    const rewardTextParams = computed(()=>{
        const details = rewardDetails.value;
        let rewardQuantity;
        let rewardNameGame = '';
        let rewardFreespinTextInner = '';
        if (details) switch(rewardType.value){
            case BonusGameRewardType.FREESPIN:
                rewardQuantity = details.freespinCount;
                rewardNameGame = details.game?.name || '';
                rewardFreespinTextInner = rewardFreespinText.value?.text || '';
                break;
            case BonusGameRewardType.FREEBET:
            case BonusGameRewardType.BONUS:
                rewardQuantity = formatMoney(details.bonusAmount || 0, {
                    currency: details.customerCurrency || 'CAN'
                });
                break;
            case BonusGameRewardType.MULTIPLIER:
                rewardQuantity = details.bonusPointMultiplier;
                break;
            default:
                rewardQuantity = 0;
                rewardNameGame = '';
        }
        return {
            rewardQuantity: String(rewardQuantity),
            rewardType: String(details?.rewardType),
            rewardNameGame,
            rewardFreespinTextInner
        };
    });
    const rewardTextComputed = computed(()=>{
        switch(rewardType.value){
            case BonusGameRewardType.FREESPIN:
                return $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_REWARD_TEXT', rewardTextParams);
            case BonusGameRewardType.MULTIPLIER:
                return $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_REWARD_TEXT_MULTIPLIER', rewardTextParams);
            case BonusGameRewardType.FREEBET:
            case BonusGameRewardType.BONUS:
            default:
                return $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_REWARD_TEXT_FREEBET', rewardTextParams);
        }
    });
    async function updateWheelData() {
        await fetchRounds(fortuneWheelId.value);
        const now = Date.now();
        const round = roundState.value?.round;
        if (round) {
            isButtonForDepositEnabled.value = round.canPurchaseAdditionalRound;
            actualRoundStartDate.value = round.actualRoundStartDate || 0;
            isAvailableClick.value = 0 !== actualRoundStartDate.value && now > actualRoundStartDate.value || showSpinButton.value;
            isInitialDataLoaded.value = true;
            hasDeposit.value = round.deposit || false;
            showCounter.value = !round.dayNumber || round.dayNumber < round.roundsCount;
            rewardType.value = rewardDetails.value?.rewardType ?? '';
            rewardText.value = rewardTextComputed.value;
        }
    }
    onMounted(async ()=>{
        await fetchActiveOrAvailableCampaigns();
        if (!isFortuneWheelAvailable.value || 0 === fortuneWheelId.value) router.push({
            name: RouteName.PROMOTIONS
        });
        await updateWheelData();
    });
    async function onButtonWheelClick() {
        const hashValue = hash(roundKey.value);
        await calculateRound(fortuneWheelId.value, hashValue);
        rewardId.value = roundWinIndex.value + 1;
        isDataLoaded.value = true;
    }
    async function onWheelStopped() {
        isModalShown.value = true;
        isAvailableClick.value = false;
        isDataLoaded.value = false;
        setShowSpinButton(false);
        await updateWheelData();
    }
    async function onCounterEnd() {
        await updateWheelData();
    }
    function onModalClose() {
        isModalShown.value = false;
        if (!isFortuneWheelAvailable.value) router.push({
            name: RouteName.PROMOTIONS
        });
    }
    function onHomeButtonClick() {
        router.push({
            name: RouteName.HOME
        });
    }
    function onTakeButtonClick() {
        if (hasDeposit.value) {
            if (rewardType.value === BonusGameRewardType.BONUS || rewardType.value === BonusGameRewardType.FREEBET) router.push({
                name: RouteName.BONUSES
            });
            else if (rewardType.value === BonusGameRewardType.MULTIPLIER || rewardType.value === BonusGameRewardType.FREESPIN) router.push({
                name: CasinoRouteName.CASINO_LOBBY
            });
            else router.push({
                name: RouteName.WITHDRAWALS
            });
        } else router.push({
            name: RouteName.DEPOSITS
        });
        isModalShown.value = false;
    }
    function onButtonDepositToSpinClick() {
        router.push({
            name: RouteName.DEPOSITS
        });
        isModalShown.value = false;
    }
    watch(isAdditionalRoundActive, async ()=>{
        await updateWheelData();
    });
    return {
        rewardId,
        rewards,
        days,
        actualRoundStartDate,
        hasDeposit,
        canPurchaseAdditionalRound,
        isAvailableClick,
        isButtonForDepositEnabled,
        isModalShown,
        rewardText,
        rewardType,
        isInitialDataLoaded,
        isDataLoaded,
        showCounter,
        onHomeButtonClick,
        onButtonWheelClick,
        onButtonDepositToSpinClick,
        onWheelStopped,
        onModalClose,
        onTakeButtonClick,
        onCounterEnd
    };
}
