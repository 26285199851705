import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createVNode as _createVNode, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = [
    "src"
];
const _hoisted_2 = [
    "src"
];
const _hoisted_4 = [
    "src"
];
import { VLoader } from '@components/loader';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import VImage from 'web/src/components/Image/VImage/VImage.vue';
import PromotionCountdown from 'web/src/modules/promotions/components/PromotionCountdown/PromotionCountdown.vue';
import { useFortuneWheel } from './composables';
import FortuneWheelButton from '../FortuneWheelButton/FortuneWheelButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FortuneWheel',
    props: {
        rewardId: {},
        rewards: {},
        isAvailableClick: {
            type: Boolean
        },
        isInitialDataLoaded: {
            type: Boolean
        },
        isDataLoaded: {
            type: Boolean,
            default: false
        },
        isButtonForDepositEnabled: {
            type: Boolean
        },
        hasDeposit: {
            type: Boolean
        },
        showCounter: {
            type: Boolean,
            default: false
        },
        timestamp: {}
    },
    emits: [
        "button-wheel-click",
        "wheel-stopped",
        "deposit-to-spin",
        "send"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { spinner, ticker, spinnerStyle, prizeSlice, prizeOffset, isStart, isInitTicker, isSpinning } = useFortuneWheel(props, emit);
        function prizeStyle(index) {
            const rotationPrize = prizeSlice.value * index - prizeOffset.value;
            return {
                '--rotate': `${rotationPrize}deg`
            };
        }
        function onButtonWheelClick() {
            emit('button-wheel-click');
            isStart.value = true;
            isInitTicker.value = true;
        }
        function onButtonDepositClick() {
            emit('deposit-to-spin');
        }
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['fortune-wheel'])
            }, [
                (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _withDirectives(_createElementVNode("img", {
                        ref_key: "ticker",
                        ref: ticker,
                        src: require('web/src/assets/images/fortune-wheel/cursor.png'),
                        alt: "",
                        class: _normalizeClass({
                            [_ctx.$style['fortune-wheel__ticker']]: true,
                            [_ctx.$style['fortune-wheel__ticker--is-init']]: _unref(isInitTicker),
                            [_ctx.$style['fortune-wheel__ticker--is-spinning']]: _unref(isSpinning)
                        }),
                        role: "presentation"
                    }, null, 10, _hoisted_1), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'fortune-wheel__ticker--desktop'
                            }
                        ]
                    ]),
                    _withDirectives(_createElementVNode("img", {
                        src: require('web/src/assets/images/fortune-wheel/circle_frame.png'),
                        alt: "",
                        class: _normalizeClass(_ctx.$style['fortune-wheel__central-frame']),
                        role: "presentation"
                    }, null, 10, _hoisted_2), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'fortune-wheel__central-frame'
                            }
                        ]
                    ])
                ], 64)),
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(_ctx.$style['fortune-wheel__wrapper'])
                }, [
                    _createCommentVNode("", true),
                    _createElementVNode("img", {
                        src: require('web/src/assets/images/fortune-wheel/central-detail.png'),
                        alt: "",
                        class: _normalizeClass(_ctx.$style['fortune-wheel__central-detail']),
                        role: "presentation"
                    }, null, 10, _hoisted_4),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['fortune-wheel__container'])
                    }, [
                        _createElementVNode("div", {
                            ref_key: "spinner",
                            ref: spinner,
                            class: _normalizeClass({
                                [_ctx.$style['fortune-wheel__spinner']]: true,
                                [_ctx.$style['fortune-wheel__spinner--is-start']]: _unref(isStart),
                                [_ctx.$style['fortune-wheel__spinner--is-spinning']]: _unref(isSpinning)
                            }),
                            style: _normalizeStyle(_unref(spinnerStyle))
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rewards, (reward, index)=>_withDirectives((_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: _normalizeClass(_ctx.$style['fortune-wheel__reward']),
                                    style: _normalizeStyle(prizeStyle(index))
                                }, [
                                    _createVNode(VImage, {
                                        src: reward.imageData.src,
                                        alt: reward.type,
                                        class: _normalizeClass(_ctx.$style['fortune-wheel__reward-img'])
                                    }, null, 8, [
                                        "src",
                                        "alt",
                                        "class"
                                    ])
                                ], 6)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'fortune-wheel__reward'
                                        }
                                    ]
                                ])), 128))
                        ], 6)
                    ], 2)
                ], 2)), [
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'fortune-wheel__wrapper'
                        }
                    ]
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['fortune-wheel__controls'])
                }, [
                    _ctx.isInitialDataLoaded ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _ctx.isAvailableClick ? _withDirectives((_openBlock(), _createBlock(FortuneWheelButton, {
                            key: 0,
                            label: _ctx.$t('WEB2_PROMOTIONS_LUCKY_SPIN'),
                            class: _normalizeClass(_ctx.$style['fortune-wheel__controls-button']),
                            onClick: onButtonWheelClick
                        }, null, 8, [
                            "label",
                            "class"
                        ])), [
                            [
                                _directive_data_test,
                                {
                                    el: 'fortune-wheel__controls-button'
                                }
                            ]
                        ]) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _ctx.showCounter ? _withDirectives((_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['fortune-wheel__controls-countdown'])
                            }, [
                                _withDirectives((_openBlock(), _createElementBlock("div", {
                                    class: _normalizeClass(_ctx.$style['fortune-wheel__controls-title'])
                                }, [
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_PROMOTIONS_FORTUNE_WHEEL_NEXT_SPIN_AVAILABLE')), 1)
                                ], 2)), [
                                    [
                                        _directive_data_test,
                                        {
                                            el: 'fortune-wheel__controls-title'
                                        }
                                    ]
                                ]),
                                _createVNode(PromotionCountdown, {
                                    "hidden-title": "",
                                    "is-alt-time-name": "",
                                    timestamp: props.timestamp,
                                    onSend: _cache[0] || (_cache[0] = ($event)=>emit('send'))
                                }, null, 8, [
                                    "timestamp"
                                ])
                            ], 2)), [
                                [
                                    _unref(vDataTestUnit),
                                    {
                                        el: 'fortune-wheel__controls-countdown'
                                    }
                                ]
                            ]) : _createCommentVNode("", true),
                            _ctx.isButtonForDepositEnabled && _ctx.hasDeposit ? _withDirectives((_openBlock(), _createBlock(FortuneWheelButton, {
                                key: 1,
                                label: _ctx.$t('WEB2_PROMOTIONS_FORTUNE_WHEEL_DEPOSIT_TO_SPIN'),
                                class: _normalizeClass(_ctx.$style['fortune-wheel__controls-button']),
                                onClick: onButtonDepositClick
                            }, null, 8, [
                                "label",
                                "class"
                            ])), [
                                [
                                    _directive_data_test,
                                    {
                                        el: 'fortune-wheel__controls-button'
                                    }
                                ]
                            ]) : _createCommentVNode("", true)
                        ], 64))
                    ], 64)) : (_openBlock(), _createBlock(_unref(VLoader), {
                        key: 0
                    }))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'FortuneWheel'
                ]
            ]);
        };
    }
});
