import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { ModalSelector } from 'web/src/modules/core/enums';
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import ModalTransition from 'web/src/components/Modal/ModalPortal/enums/ModalTransition';
import { useFortuneWheelModal } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FortuneWheelModal',
    props: {
        rewardText: {},
        hasDeposit: {
            type: Boolean
        },
        isButtonForDepositEnabled: {
            type: Boolean
        },
        rewardType: {},
        modalProperties: {}
    },
    emits: [
        "button-click",
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const transition = void 0;
        const { modalShowFortuneWheelWindow, onButtonClick } = useFortuneWheelModal(props, emit);
        function onClose() {
            emit('close');
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ModalPortal, {
                selector: _unref(ModalSelector).BODY,
                transition: _unref(transition)
            }, {
                default: _withCtx(()=>[
                        _createVNode(ConfirmModal, _mergeProps(_unref(modalShowFortuneWheelWindow), {
                            onClose: onClose,
                            onButtonClick: _unref(onButtonClick)
                        }), {
                            icon: _withCtx(()=>[
                                    _createElementVNode("img", {
                                        src: require('web/src/assets/images/fortune-wheel/fortune-wheel.svg'),
                                        class: _normalizeClass(_ctx.$style['fortune-wheel-modal__icon']),
                                        alt: ""
                                    }, null, 10, _hoisted_1)
                                ]),
                            default: _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_ctx.rewardText), 1)
                                ]),
                            _: 1
                        }, 16, [
                            "onButtonClick"
                        ])
                    ]),
                _: 1
            }, 8, [
                "selector",
                "transition"
            ])), [
                [
                    _directive_auto_id,
                    'FortuneWheelModal'
                ]
            ]);
        };
    }
});
