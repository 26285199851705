import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import VTurnDeviceNotification from 'web/src/components/TurnDeviceNotification/VTurnDeviceNotification/VTurnDeviceNotification.vue';
import { useFortuneWheelPage } from './composables';
import FortuneWheelButton from '../../components/FortuneWheelButton/FortuneWheelButton.vue';
import FortuneWheel from '../../components/FortuneWheel/FortuneWheel.vue';
import FortuneWheelModal from '../../components/FortuneWheelModal/FortuneWheelModal.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FortuneWheelRoutePage',
    setup (__props) {
        const { rewards, rewardId, days, hasDeposit, isModalShown, actualRoundStartDate, isAvailableClick, isButtonForDepositEnabled, rewardText, rewardType, isDataLoaded, isInitialDataLoaded, showCounter, onHomeButtonClick, onButtonWheelClick, onButtonDepositToSpinClick, onWheelStopped, onCounterEnd, onModalClose, onTakeButtonClick } = useFortuneWheelPage();
        const iconCup = IconName.CUP;
        const iconHome = IconName.HOME;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['fortune-wheel-page'])
            }, [
                _unref(isInitialDataLoaded) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['fortune-wheel-page__header'])
                }, [
                    _createVNode(FortuneWheelButton, {
                        "icon-name": _unref(iconCup),
                        label: _unref(days),
                        "is-button": false
                    }, null, 8, [
                        "icon-name",
                        "label"
                    ]),
                    _createVNode(FortuneWheelButton, {
                        "icon-name": _unref(iconHome),
                        onClick: _unref(onHomeButtonClick)
                    }, null, 8, [
                        "icon-name",
                        "onClick"
                    ])
                ], 2)) : _createCommentVNode("", true),
                _createVNode(FortuneWheel, {
                    "reward-id": _unref(rewardId),
                    rewards: _unref(rewards),
                    "is-available-click": _unref(isAvailableClick),
                    timestamp: _unref(actualRoundStartDate),
                    "is-initial-data-loaded": _unref(isInitialDataLoaded),
                    "is-data-loaded": _unref(isDataLoaded),
                    "has-deposit": _unref(hasDeposit),
                    "is-button-for-deposit-enabled": _unref(isButtonForDepositEnabled),
                    "show-counter": _unref(showCounter),
                    onButtonWheelClick: _unref(onButtonWheelClick),
                    onWheelStopped: _unref(onWheelStopped),
                    onDepositToSpin: _unref(onButtonDepositToSpinClick),
                    onSend: _unref(onCounterEnd)
                }, null, 8, [
                    "reward-id",
                    "rewards",
                    "is-available-click",
                    "timestamp",
                    "is-initial-data-loaded",
                    "is-data-loaded",
                    "has-deposit",
                    "is-button-for-deposit-enabled",
                    "show-counter",
                    "onButtonWheelClick",
                    "onWheelStopped",
                    "onDepositToSpin",
                    "onSend"
                ]),
                _createCommentVNode("", true),
                _unref(isModalShown) ? (_openBlock(), _createBlock(FortuneWheelModal, {
                    key: 2,
                    "has-deposit": _unref(hasDeposit),
                    "is-button-for-deposit-enabled": _unref(isButtonForDepositEnabled),
                    "reward-text": _unref(rewardText),
                    "reward-type": _unref(rewardType),
                    onButtonClick: _unref(onTakeButtonClick),
                    onClose: _unref(onModalClose)
                }, null, 8, [
                    "has-deposit",
                    "is-button-for-deposit-enabled",
                    "reward-text",
                    "reward-type",
                    "onButtonClick",
                    "onClose"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'FortuneWheelRoutePage'
                ]
            ]);
        };
    }
});
