import { computed } from 'vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { ModalWidth } from 'web/src/components/Modal/enums';
import { DialogAction } from 'web/src/modules/dialogs/enums';
export default function useFortuneWheelModal(props, emit) {
    const { $translate } = useI18n();
    const textDeposit = $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_REWARD_BUTTON_DEPOSIT');
    const textTake = $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_REWARD_BUTTON_TAKE');
    const buttonLabel = computed(()=>props.hasDeposit ? textTake.value : textDeposit.value);
    const modalShowFortuneWheelWindow = computed(()=>({
            isAlert: true,
            modal: {
                isOverlayCloseAllowed: false,
                width: ModalWidth.SMALL,
                title: $translate('WEB2_PROMOTIONS_FORTUNE_WHEEL_CONGRATULATION').value,
                buttons: [
                    {
                        kind: ButtonKind.PRIMARY,
                        action: DialogAction.CONFIRM,
                        label: buttonLabel.value
                    },
                    {
                        kind: ButtonKind.TRANSPARENT,
                        action: DialogAction.MODAL_CLOSE,
                        label: $translate('JSP_PCL_FBOT_CLOSE').value
                    }
                ],
                dataTestId: 'fortune-wheel-dasd'
            }
        }));
    function onButtonClick(param) {
        let { action } = param;
        if (action === DialogAction.CONFIRM) emit('button-click');
        if (action === DialogAction.MODAL_CLOSE) emit('close');
    }
    return {
        modalShowFortuneWheelWindow,
        onButtonClick
    };
}
