import { computed, ref } from 'vue';
import { Tag } from '@leon-hub/tags';
import hasSlot from 'web/src/utils/vue/hasSlot';
import { getBaseAttributesForButton, getIconPropsForButton } from './utils';
export default function useFortuneWheelButton(props, slots) {
    const mainTag = ref();
    const iconProps = computed(()=>getIconPropsForButton(props, hasSlot(slots, 'default')));
    const computedAttributes = computed(()=>getBaseAttributesForButton(props));
    const computedTag = computed(()=>props.isButton ? Tag.BUTTON : Tag.DIV);
    const haveIcon = computed(()=>!!props.iconName);
    const hasDefaultContent = computed(()=>hasSlot(slots, 'default'));
    return {
        mainTag,
        computedTag,
        computedAttributes,
        haveIcon,
        iconProps,
        hasDefaultContent
    };
}
